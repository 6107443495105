const reisekosten = ({ getTask, getAnswer }) => {
  const taskProps = {
    courseId: "hoelderlins-reisen",
    chapterId: "01-friedrich-hoelderlin",
    taskId: "reisekosten",
  }

  const task = getTask(taskProps)
  const solutionAnreise = task.solution.anreise
  const solutionSpeier = task.solution.speier
  const solutionRueckweg = task.solution.rueckweg
  const solutionTotal = solutionAnreise + solutionSpeier + solutionRueckweg
  const answerAnreise = parseInt(
    getAnswer({
      ...taskProps,
      taskId: "reisekosten-anreise",
    })?.kosten || 0
  )
  const answerSpeier = parseInt(
    getAnswer({
      ...taskProps,
      taskId: "reisekosten-speier",
    })?.kosten || 0
  )
  const answerRueckweg = parseInt(
    getAnswer({
      ...taskProps,
      taskId: "reisekosten-rueckweg",
    })?.kosten || 0
  )
  const answerTotal = parseInt(
    getAnswer({
      ...taskProps,
      taskId: "reisekosten-gesamt",
    })?.kosten || 0
  )

  const allCorrect =
    solutionAnreise === answerAnreise &&
    solutionSpeier === answerSpeier &&
    solutionRueckweg === answerRueckweg &&
    solutionTotal === answerTotal

  return {
    solutionAnreise,
    solutionSpeier,
    solutionRueckweg,
    solutionTotal,
    answerAnreise,
    answerSpeier,
    answerRueckweg,
    answerTotal,
    allCorrect,
  }
}

export default reisekosten
